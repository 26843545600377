import {
  Notification,
  notificationTypes
} from "../common/Notification";

const getPositionGyroscope = async () => {
  try {
    const port = await getSelectedPort();
    await port.open({baudRate: 115200, bufferSize: 10});
    const portInfo = port.getInfo();

    localStorage.setItem('gyroscopeSerialRequestPort', portInfo?.usbVendorId);
    window.dispatchEvent(new Event("storageGyroscope"));

    var ax
    var ay
    var az

    let startDate = Date.now();
    let errorDate = startDate + (10 * 1000);

    while (true) {
      let reader = await port.readable.getReader();
      const { done, value } = await reader.read();
      reader.releaseLock();

      if (done) {
        break;
      }
      if (value.length >= 8 && value[0] == 0x55) {
        console.log('value: ', value);
        switch (value[1]) {
          case 0x53:
            ax = ((value[3] << 8 | value[2]) /32768) * 180
            ay = ((value[5] << 8 | value[4]) /32768) * 180
            az = ((value[7] << 8 | value[6]) /32768) * 180
            break;
          default:
            continue;
        }

        ax = ax > 180 ? ax - 360 : ax
        ay = ay > 180 ? ay - 360 : ay
        az = az > 180 ? az - 360 : az
      }
      if (ax && ay && az) {
        reader.releaseLock();
        await port.close();
        return {
          x: (ax||0).toFixed(2),
          y: (ay||0).toFixed(2),
          z: (az||0).toFixed(2),
          port: port,
          usbProductId: portInfo?.usbProductId
        }
        break
      }

      if (Date.now() >= errorDate) {
        console.log('error____start____repeat')
        await reader.releaseLock();
        await port.close();
        await new Promise(r => setTimeout(r, 2000));
        return await getPositionGyroscope()
        break
      }
    }
  } catch (err) {
    console.log('err: ', err);
    // await initGyroscope();
    // return await getPositionGyroscope()
  }
};
const initGyroscope = async () => {
  const port = await getSelectedPort();
  const usbVendorId = port.getInfo()?.usbVendorId;
  const portInfo = port.getInfo();
  const eventOpenPort = await port.open({ baudRate: 19200, bufferSize: 10})
    .catch((res) => {
      return {error: res.message}
    });
  if (eventOpenPort?.error) {
    localStorage.removeItem('gyroscopeSerialRequestPort');
    window.dispatchEvent(new Event("storageGyroscope"));
    Notification({
      type: notificationTypes.error,
      message: eventOpenPort?.error
    })
    return false
  }

  localStorage.setItem('gyroscopeSerialRequestPort', usbVendorId);
  window.dispatchEvent(new Event("storageGyroscope"));

  await port.close();
  return true
}
const getSelectedPort = async () => {
  let port = null;
  const gyroscope = localStorage.getItem('gyroscopeSerialRequestPort');
  if (!!gyroscope) {
    const ports = await navigator.serial.getPorts({
      filters: [{ usbVendorId: gyroscope }]
    });
    port = ports[0];

    if (Object.keys(port.getInfo()).length === 0) {
      port = await navigator.serial.requestPort();
    }

    // port = gyroscope;
  } else {
    port = await navigator.serial.requestPort();
  }
  return port
}

const getSerialRequestPort = async () => {};

export {
  initGyroscope,
  getPositionGyroscope,
  getSerialRequestPort
}
